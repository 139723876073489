var presentAfterpay = presentAfterpay || {};
var prodcat = prodcat || {};

(function ($) {

  // When the menu changes, trigger skuSelect:
  $(document).on('change', 'select.js-sku-menu', function () {
    var skuId = $(this).val();
    var skuBaseId = skuId - 0; // Convert to INT
    var sku = prodcat.data.getSku(skuBaseId);
    var amount = sku.PRICE;

    amount = amount * 100;
    $(this).closest('.js-product').trigger('product.skuSelect', [skuBaseId]);

    if (Drupal.settings?.common?.display_installment_price) {
      presentAfterpay?.afterpayConfig(amount);
    }
  });

  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    // Price update logic
    var sku = prodcat.data.getSku(skuBaseId);

    $product.find('select.js-sku-menu').val(skuBaseId);
    $product.find('select.js-sku-menu.selectBox-attached').selectBox('value', skuBaseId);

    // Scroll page after sku selection
    var $image = $product.find('.product-full__image');

    if ($product.hasClass('product-full') && $image.length && $image.position()) {
      $('html, body').animate({ scrollTop: $image.position().top });
    }

    /* ------- Quickshop Product name & subline ------- */
    var $nameLink = $product.find('.js-product-name-link');

    if ($nameLink.length) {
      nameText = sku ? sku.SHADENAME : prod ? prod.PROD_RGN_NAME : '';
      $nameLink.html(nameText);
    }

    var $sublineLink = $product.find('.js-product-subline-link');

    if ($sublineLink.length) {
      var subLineText = sku ? sku.SKU_APPLICATION : prod ? prod.PROD_RGN_SUBHEADING : '';
      var bytext = site && site.translations && site.translations.product ? site.translations.product.by + ' ' : '';
      var text = Drupal.settings.common.show_by_text ? bytext : '';

      $sublineLink.html(text + subLineText);
    }

    $product.trigger('product.skuSelectUpdateImage', skuBaseId);

    /* ------- Sku Prices ------- */
    var product_sku_price = site.template.get({
      name: 'product_sku_price',
      data: sku // Only pare the sku because if sku is 0 then the price will be rendered empty
    });

    $('.product-sku-price', $product)
      .fadeTo('slow', 0, 'linear', function () {
        $(this).html($(product_sku_price).html());
      })
      .fadeTo('slow', 1, 'linear');

    /* ------- Sku Accordion ------- */
    // Buid the data from sku or product
    var product_accordion_area = site.template.get({
      name: 'product_accordion_area',
      data: sku && (sku.SHORT_DESC || sku.DESCRIPTION || sku.ATTRIBUTE_DESC_1) ? sku : prod // Parse the SKU Descripotion if there is one and show the SKU details, otherwise parse the PROD and we'll show the default description for the product
    });

    $('.product-full__accordion', $product)
      .fadeTo('slow', 0, 'linear', function () {
        $(this).html($(product_accordion_area).html());
        // Refresh the accordion if there is one
        Drupal.behaviors.productAccordionArea.attach();
      })
      .fadeTo('slow', 1, 'linear');

    /* ------- Complete The Collection ------- */
    if ($product.hasClass('product-full')) {
      // Only run on SPP route update
      $(document).trigger('complete_the_collection_update', sku);
    }
  });

  $(document).on('product.skuSelectUpdateImage', '.js-product', function (e, skuBaseId) {
    var $product = $(this);
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    // Price update logic
    var sku = prodcat.data.getSku(skuBaseId);
    /* ------- Sku Images ------- */
    var product_full_image = site.template.get({
      name: 'product_full_image',
      data: sku ? sku : prod && prod.defaultSku ? prod.defaultSku : prod // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    });

    $('.product-full__image', $product)
      .fadeTo('slow', 0, 'linear', function () {
        $(this).html($(product_full_image).html());
        // Refresh the slick slider if there is one
        Drupal.behaviors.productFullImageV1.attach($product);
      })
      .fadeTo('slow', 1, 'linear');
  });
})(jQuery);
